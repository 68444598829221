// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-employer-page-js": () => import("./../../../src/templates/employerPage.js" /* webpackChunkName: "component---src-templates-employer-page-js" */),
  "component---src-templates-playground-enigma-index-js": () => import("./../../../src/templates/playground/enigma/index.js" /* webpackChunkName: "component---src-templates-playground-enigma-index-js" */),
  "component---src-templates-work-samples-tovala-index-js": () => import("./../../../src/templates/work-samples/tovala/index.js" /* webpackChunkName: "component---src-templates-work-samples-tovala-index-js" */)
}

